<template>
  <div class="container">
    <div class="content">
      <div class="title">
        <div class="title-top">
          <img src="../assets/about/icon.png"
               class="img-icon"
               alt="">
          <h1 class="title-h1">ABOUT</h1>
        </div>
        <div class="title-middle">
          <h1 class="title-h1">Origin</h1>
        </div>
        <div class="title-bottom"
             v-if="$store.state.isZH">
          关于欧瑞捷
        </div>
      </div>
      <div class="introduction">
        <div class="title">
          <h3 class="title-h3">{{ $t('about.aboutOrg') }}</h3>
          <div class="text">
            <div class="text-left">
              <div class="text-size">{{ $t('about.paragraph1') }}</div>
              <div class="text-size">{{ $t('about.paragraph2') }}</div>
              <div class="text-size">{{ $t('about.paragraph3') }}</div>
            </div>
            <div class="text-right">
              <div class="text-size">{{ $t('about.paragraph4') }}</div>
              <div class="text-size">
                {{ $t('about.paragraph5') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    color: #fff;
    height: 100%;
    padding: 22% 15% 0;
    background: url("../assets/about/bgc.png") no-repeat;
    background-size: cover;
    box-sizing: border-box;
    .title {
      color: #fff;
      padding-bottom: 10%;
      box-sizing: border-box;
      .img-icon {
        height: 50px;
        padding-right: 6%;
      }
      .title-top,
      .title-middle {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .title-h1 {
        font-size: 70px;
      }
      .title-bottom {
        display: flex;
        justify-content: flex-end;
        font-size: 47px;
        font-weight: 600;
      }
    }
    .introduction {
      padding-bottom: 20%;
      box-sizing: border-box;
      .title {
        .title-h3 {
          font-size: 36px;
          padding-bottom: 8%;
        }
        .text {
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          .text-size {
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 10%;
          }
          .text-left {
            width: 47%;
          }
          .text-right {
            width: 45%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .container {
    .content {
      padding: 22% 5% 0;
      .title {
        .img-icon {
          height: 30px;
          padding-right: 5%;
        }
        .title-h1 {
          font-size: 38px;
        }
        .title-bottom {
          font-size: 22px;
        }
      }
      .introduction {
        .title {
          .title-h3 {
            font-size: 22px;
            padding-bottom: 10%;
          }
          .text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            font-size: 20px;
            .text-size {
              font-size: 12px;
              line-height: 30px;
              margin-bottom: 10%;
            }
            .text-left,
            .text-right {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>